import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"
import Header from "./header";
import Footer from "./footer";

import './global.css';

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
        }
      }
    }
  }
  `)

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(28),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <Header location={location} title={title} menuLinks={data.site.siteMetadata.menuLinks} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
